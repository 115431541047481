<template>
    <div>
      <van-sticky :offset-top="0">
        <demo-block :title="('')">
          <van-nav-bar :title="('考勤查询')" :left-text="('返回')" left-arrow @click-left="this.$router.go(-1)">
            <template #right>
              <van-icon name="home" size="18" />
            </template>
          </van-nav-bar>
        </demo-block>
      </van-sticky>
      <van-tabs v-model:active="active" sticky>
        <van-tab title="考勤明细">
          <demo-block>
            <van-cell-group v-for="i in lscurorder" :key="i.rn" style="margin-bottom:20px;margin-top:5px;" inset>
              <van-cell :title="i.termname" :value="i.opfare" >
                <template #title>
                  <van-tag type="primary" size="large">{{i.termname}}                    
                  </van-tag>
                </template>
                <template #value>
                  <van-tag type="warning"  size="large">{{i.ioflagname}}</van-tag>                
                </template>
  
              </van-cell>
              <van-cell title="考勤时间" :value="i.opertime"  />
            </van-cell-group>
          </demo-block>
        </van-tab>
        <van-tab title="考勤汇总">
          <demo-block>
            <van-cell-group v-for="i in lscursumlist" :key="i.rn" style="margin-bottom:20px;margin-top:5px;" inset>
              <van-cell>
                <template #title>
                  <van-tag type="primary" size="large">{{i.attenddate}}                    
                  </van-tag>
                </template>
                <template #value>
                  <van-tag type="success"  size="large" style="margin-right:10px;">{{ '早：  ' + i.ststatusname}}</van-tag>
                  <van-tag type="warning"  size="large">{{'晚：  ' + i.endstatusname}}</van-tag>
                </template>
  
              </van-cell>
              <van-cell v-if="i.latemin>0 || i.earlymin>0 || i.kghours>0">
                <template #title>
                  <van-tag type="danger" size="large">异常数据                    
                  </van-tag>
                </template>
                <template #value>
                  <van-tag v-if="i.latemin>0" type="danger"  size="large" style="margin-right:5px;margin-left:-40px;">{{ '迟到' + i.latemin + '分'}}</van-tag>
                  <van-tag v-if="i.earlymin>0" type="danger"  size="large">{{'早退' + i.earlymin+ '分'}}</van-tag>
                  <van-tag v-if="i.kghours>0" type="danger"  size="large">{{'旷工' + i.kghours+ '分'}}</van-tag>
                </template>
              </van-cell>
            </van-cell-group>
          </demo-block>
        </van-tab>
      </van-tabs>
      <van-overlay :show="isLoading" >
        <view class="wrapper">
          <view class="block" catch:tap="noop">
            <van-loading size="24px" color="#ffffff">{{loadcontent}}</van-loading>
          </view>
        </view>
      </van-overlay>
    </div>
  </template>
  
  <script>
  import { Cell,CellGroup,Checkbox, Button, Dialog, CheckboxGroup, Card, SubmitBar, Toast, Tabs, Tab, Sticky, NavBar, Icon, Tag,  Overlay, Loading } from 'vant';
  import { requestGetAttendDetail } from '../../api/api'
  
  export default {
    components: {
      [Button.name]: Button,
      [Card.name]: Card,
      [Checkbox.name]: Checkbox,
      [SubmitBar.name]: SubmitBar,
      [CheckboxGroup.name]: CheckboxGroup,
      [Sticky.name]: Sticky,
      [NavBar.name]: NavBar,
      [Icon.name]: Icon,
      [Tabs.name]: Tabs,
      [Tab.name]: Tab,
      [Tag.name]:Tag,
      [Dialog.name]: Dialog,
      [Overlay.name]: Overlay,
      [Loading.name]: Loading,
      [Cell.name]: Cell,
      [CellGroup.name]: CellGroup
    },
    data() {
      return {
        loadcontent: '加载中...',
        isLoading: false,
        active: 0,
        lscurorder: [],
        lscursumlist: [],
        lsorder: [],
        ordertype: [
          { id: 0, key: "all", title: "考勤明细", icon: "wap-home" },
          { id: 1, key: "cash", title: "考勤结果", icon: "logistics" }
        ]
      }
    },
    watch: {
      active(val) {
        console.log('获得的active发生变化：' + val);
        setTimeout(() => {
          this.GetCurOrderInfo(val);
        }, 300);
      },
    },
    mounted() {
      this.GetCurOrderInfo(0)
      
  
    },
    methods: {
      async GetCurOrderInfo (ordertype) {
       
        if (ordertype === 0) this.lscurorder = []
        else this.lscursumlist = []
        this.isLoading = true
        const res = await requestGetAttendDetail({ accesstoken: this.$store.state.accesstoken, ordertype: ordertype})
        console.log(res)
        if (res.return_code === '0') {
            if (ordertype === 0) this.lscurorder = res.rows
            else this.lscursumlist = res.rows
          this.isLoading = false
          if (this.lscurorder.length === 0){
            Toast({
                message: '无数据!',
                icon: 'fail',
            });
          }
  
        } else {
            Toast({
                message: res.return_message,
                icon: 'fail',
            });
            this.isLoading = false
        }
         
      }
    }
  };
  </script>
  
  <style lang="less">
  .wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  .card-goods {
    padding: 10px 0;
    background-color: #fff;
  
    &__item {
      position: relative;
      background-color: #fafafa;
  
      .van-checkbox__label {
        width: 100%;
        height: auto; // temp
        padding: 0 10px 0 15px;
        box-sizing: border-box;
      }
  
      .van-checkbox__icon {
        top: 50%;
        left: 10px;
        z-index: 1;
        position: absolute;
        margin-top: -10px;
      }
  
      .van-card__price {
        color: #f44;
      }
    }
  }
  </style>
  